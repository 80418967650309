import axios from 'axios'
// import AppHelper from '../app/AppHelper'

const MPClient = axios.create({
	baseURL: ''
})

const PAYMENT_MODEL = {
	clientData: {},
	productData: [],
	paymentData: {},
	agreement: false,
	ticketValue: '',
	operationNo: 0,
	clientApi: '',
	blikCode: '',
	marketingAgreement: false,
	marketingSmsAgreement: false,
	captchaToken: ''
}

const getData = (clientData, product, payment, agreement, captchaToken, phoneVerifyData, blikCode, marketingAgreement, marketingAgreementSMS) => {
	clientData.forEach(cd => {
		PAYMENT_MODEL.clientData[cd.name] = cd.value
	})
	if (localStorage.getItem('api') != null && localStorage.getItem('api') === 'android') {
		PAYMENT_MODEL.clientApi = 'AndroidLiteShop'
	} else {
		PAYMENT_MODEL.clientApi = 'PaymentsLiteShop'
	}	
	PAYMENT_MODEL.productData = product
	PAYMENT_MODEL.paymentData = payment
	PAYMENT_MODEL.agreement = agreement
	PAYMENT_MODEL.blikCode = blikCode
	PAYMENT_MODEL.marketingAgreement = marketingAgreement
	PAYMENT_MODEL.marketingSmsAgreement = marketingAgreementSMS
	PAYMENT_MODEL.ticketValue = phoneVerifyData.find(data => data.name === 'ticketValue').value
	PAYMENT_MODEL.operationNo = phoneVerifyData.find(data => data.name === 'operationNo').value
	PAYMENT_MODEL.captchaToken = captchaToken
	return JSON.stringify(PAYMENT_MODEL)
}

const getConfig = async () => {
	try {
		const response = await MPClient.get(`${process.env.REACT_APP_URL}/api/1.0/paymentslite/service?name=Paysafecard`)
		return JSON.parse(response.data.Data)
	} catch (error) {
		throw new Error('getting configuration error')
	}
}

const pay = async (clientData, product, payment, agreement, captchaToken, phoneVerifyData, blikInputCurrent, marketingEmailAgreement, marketingSmsAgreement) => {
	try {
		const config = {
			headers: { 'Content-Type': 'application/json;charset=UTF-8' }
		}
		const data = getData(clientData, product, payment, agreement, captchaToken, phoneVerifyData, blikInputCurrent ? blikInputCurrent.value : null, marketingEmailAgreement, marketingSmsAgreement)
		const response = await MPClient.post(`${process.env.REACT_APP_URL}/api/1.0/paymentslite/pay`, data, config)
		return response.data
	} catch (error) {
		console.log(error)
	}
}

const checkStatus = async (user, task) => {
	try {
		const response = await MPClient.get(`${process.env.REACT_APP_URL}/api/1.0/paymentslite/checkstatus?user=${user}&task=${task}`)
		if (response.data.Data == null)
			throw new Error('empty response error')	
		return response.data.Data
	} catch (error) {
		throw new Error('check status error')
	}
}

const addTicket = async (phoneNo) => {
	try {
		const config = {
			headers: { 'Content-Type': 'application/json;charset=UTF-8' }
		}
		const response = await MPClient.post(`${process.env.REACT_APP_URL}/api/1.0/paymentslite/addTicket`, { "phoneNo": phoneNo, "clientApi": "PaymentsLiteShop"}, config)
		return response.data
	} catch (error) {
		return null
	}
}

const checkTicket = async (phoneNo, ticketValue, operationNo) => {
	try {
		const response = await MPClient.get(`${process.env.REACT_APP_URL}/api/1.0/paymentslite/checkTicket?phoneNo=${phoneNo}&ticketValue=${ticketValue}&operationNo=${operationNo}`)
		if (response.data.Status == null)
			return false
		if (response.data.Status === 'OK')
			return true
		return false
	} catch (error) {
		return false
	}
}

export { getConfig, pay, checkStatus, addTicket, checkTicket }