import React, { lazy } from 'react'
import Loading from '../common/Loading'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

const ShoppingCartPage = lazy(() => import('../views/cart/ShoppingCartPage'))
const ErrorPage = lazy(() => import('../views/fulfillment/ErrorPage'))
const PendingPage = lazy(() => import('../views/fulfillment/PendingPage'))
const ThankYouPage = lazy(() => import('../views/fulfillment/ThankYouPage'))
const HomePage = lazy(() => import('../views/home/HomePage'))
const ProductPage = lazy(() => import('../views/product/ProductPage'))
const ErrorBoundary = lazy(() => import('../common/ErrorBoundary'))
const Header = lazy(() => import('../components/Header'))
const Footer = lazy(() => import('../components/Footer'))
const TermsPage = lazy(() => import('../views/terms/TermsPage'))
const PrivacyPolicyPage = lazy(() => import('../views/terms/PrivacyPolicyPage'))
const PaymentMethodsPage = lazy(() => import('../views/terms/PaymentMethodsPage'))
const SecurityPage = lazy(() => import('../views/terms/SecurityPage'))

const getRouting = (props) => {
	return (
		<React.Suspense fallback={<Loading />}>
			<div className="App" ref={props.containerRef}>
				<ErrorBoundary>
					<BrowserRouter>
						<Header products={props.products} containerRef={props.containerRef} setProduct={props.setProduct} setProducts={props.setProducts} dataLayer={props.dataLayer} />
						<Switch>
							<Route exact path="/">
								<HomePage products={props.products} isLoading={props.isLoading} dataLayer={props.dataLayer} />
							</Route>
							<Route exact path="/cart">
								<ShoppingCartPage isLoading={props.isLoading} paymentMethods={props.paymentMethods}
									setErrorMessage={props.setErrorMessage} setBlikStatusData={props.setBlikStatusData}
									dataLayer={props.dataLayer} products={props.products} />
							</Route>
							<Route path="/product/:service/:prd">
								<ProductPage isLoading={props.isLoading} setIsLoading={props.setIsLoading}
									products={props.products} setProducts={props.setProducts}
									setPaymentMethods={props.setPaymentMethods} setProduct={props.setProduct}
									dataLayer={props.dataLayer} />
							</Route>
							<Route path="/product/:service/">
								<ProductPage isLoading={props.isLoading} setIsLoading={props.setIsLoading}
									products={props.products} setProducts={props.setProducts}
									setPaymentMethods={props.setPaymentMethods} setProduct={props.setProduct}
									dataLayer={props.dataLayer} product={props.product} />
							</Route>
							<Route exact path="/pending">
								<PendingPage blikStatusData={props.blikStatusData} setErrorMessage={props.setErrorMessage} />
							</Route>
							<Route exact path="/thankyou">
								<ThankYouPage />
							</Route>
							<Route exact path="/error">
								<ErrorPage errorMessage={props.errorMessage} />
							</Route>
							<Route exact path="/terms">
								<TermsPage />
							</Route>
							<Route exact path="/privacy-policy">
								<PrivacyPolicyPage />
							</Route>
							<Route exact path="/payment-methods">
								<PaymentMethodsPage />
							</Route>
							<Route exact path="/security">
								<SecurityPage />
							</Route>


							<Route path="/redirect" component={(data) => {
								window.location.href = process.env.REACT_APP_URL + "/" + data.location.state.detail
								return <Loading />
							}} />
							<Route path="/redirectBlog" component={(data) => {
								window.location.href = process.env.REACT_APP_URL_PSCSHOP + data.location.state.detail
								return <Loading />
							}} />
						</Switch>
						<Footer products={props.products} setProduct={props.setProduct} dataLayer={props.dataLayer} />
					</BrowserRouter>
				</ErrorBoundary>
			</div>
		</React.Suspense>
	)
}

export { getRouting }