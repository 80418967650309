import ReactGA from 'react-ga'

const enable = process.env.REACT_APP_GTM_ENABLE === "true"

function initEC() {
    if (!enable) { return }
    ReactGA.initialize(
        [
            {
                trackingId: 'UA-197041405-1',
                gaOptions: {
                    name: 'GA_app_mojeplatnosci',
                }
            },
            {
                trackingId: 'UA-209486139-1',
                gaOptions: {
                    name: 'GA_sklep_mojeplatnosci',
                }
            }
        ]
    );

    return window.dataLayer
}

const clearECDataLayer = (dataLayer) => {
    if (!enable) { return }
    dataLayer.push({
        'event': 'clear',
        'ecommerce': null
    });
    dataLayer.push({ ecommerce: null });  // czyszczenie obiektu e-commerce
}

const addECStatClickProduct = (product, dataLayer) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
            'click': {
                'product': [{
                    'name': product.name,                      // name lub ID jest wymagany parametrem
                    'id': product.id,
                    'price': product.price,
                    'barcodeMask': product.barcodeMask,
                    'shortName': product.shortName
                }]
            }
        }
    });
    
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        event: "view_item",
        ecommerce: {
            items: [
                {
                    item_id: product.id,
                    item_name: product.name,
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Paysafecard",
                    item_category: product.shortName,
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "psc_products",
                    item_list_name: "PSC products",
                    item_variant: product.id,
                    location_id: "Shop",
                    price: (product.totalAmount ? product.totalAmount : 0.00),
                    quantity: 1
                }
            ]
        }
    });
}

const addECStatProductAddToCart = (product, quantity, dataLayer) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        'event': 'addToCart',
        'ecommerce': {
            'currencyCode': 'PLN',
            'add': {
                'products': [{
                    'name': product.name,                      // name lub ID jest wymagany parametrem
                    'id': product.id,
                    'price': product.price,
                    'barcodeMask': product.barcodeMask,
                    'shortName': product.shortName,
                    'quantity': quantity
                }]
            }
        }
    });

    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            items: [
                {
                    item_id: product.id,
                    item_name: product.name,
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Paysafecard",
                    item_category: product.shortName,
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "psc_products",
                    item_list_name: "PSC products",
                    item_variant: product.id,
                    location_id: "Shop",
                    price: (product.totalAmount ? product.totalAmount : 0.00),
                    quantity: 1
                }
            ]
        }
    });
}

const addECStatProductRemovedFromCart = (product, quantity, dataLayer) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        'event': 'removeFromCart',
        'ecommerce': {
            'remove': {
                'products': [{
                    'name': product.name,                      // name lub ID jest wymagany parametrem
                    'id': product.id,
                    'price': product.price,
                    'barcodeMask': product.barcodeMask,
                    'shortName': product.shortName,
                    'quantity': quantity
                }]
            }
        }
    });

    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
            items: [
                {
                    item_id: product.id,
                    item_name: product.name,
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Paysafecard",
                    item_category: product.shortName,
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "psc_products",
                    item_list_name: "PSC products",
                    item_variant: product.id,
                    location_id: "Shop",
                    price: (product.totalAmount ? product.totalAmount : 0.00),
                    quantity: 1
                }
            ]
        }
    });
}

const addECStatCart = (products, dataLayer) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
            'checkout': {
                'actionField': { 'step': 1, 'option': 'review cart' }, //numer kroku, nazwa akcji
                'products': products
            }
        }
    });


}

const addECStatCartStep = (step, checkoutOption, dataLayer, products) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce
    dataLayer.push({
        'event': 'checkoutOption',
        'ecommerce': {
            'checkout_option': {
                'actionField': { 'step': step, 'option': checkoutOption },
                'products': products
            }
        }
    });

    ReactGA.ga('GA_app_mojeplatnosci', 'pageview');
}

const addECStatBuy = (products, dataLayer, total, task) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)  // czyszczenie obiektu e-commerce

    dataLayer.push({
        'ecommerce': {
            'purchase': {
                'products': products
            }
        }
    })
    dataLayer.push({
        'event': 'conversion',
        'send_to': 'AW-10797013326/K6HtCOSF34EDEM6ytZwo',
        'value': total,
        'products': products,
        'currency': 'PLN'
    })

    ReactGA.ga('GA_app_mojeplatnosci.send', 'pageview')
    ReactGA.ga('GA_sklep_mojeplatnosci.send', 'pageview')
    ReactGA.ga('GA_app_mojeplatnosci.send', 'event', 'psc_form', 'Zakup PSC', 'sklep.mojeplatnosci.pl', total);
    ReactGA.ga('GA_sklep_mojeplatnosci.send', 'event', 'psc_form', 'Zakup PSC', 'sklep.mojeplatnosci.pl', total);
    ReactGA.ga('GA_app_mojeplatnosci.send', 'event', 'psc_form', 'purchase', 'Zakup PSC', products); //pscValue - pobrana wartość koszyka
    ReactGA.ga('GA_sklep_mojeplatnosci.send', 'event', 'psc_form', 'purchase', 'Zakup PSC', products); //pscValue - pobrana wartość koszyka    

    dataLayer.push({
        event: "purchase",
        ecommerce: {
            transaction_id: task, //id transakcji
            affiliation: "",
            value: products[0].totalAmount, //wartość produktów po odliczeniu rabatów
            tax: 0.00, //wartość podatku
            shipping: 0.00, //koszt dostawy
            currency: "PLN",
            coupon: "",
            items: [{
                item_id: products[0].id,
                item_name: products[0].name,
                affiliation: "BillBird",
                coupon: "",
                currency: "PLN",
                discount: 0.00,
                index: 0,
                item_brand: "Paysafecard",
                item_category: products[0].shortName,
                item_category2: "",
                item_category3: "",
                item_category4: "",
                item_category5: "",
                item_list_id: "psc_products",
                item_list_name: "PSC products",
                item_variant: products[0].id,
                location_id: "Shop",
                price: products[0].totalAmount,
                quantity: 1
            }]
        }
    });
}

const addECStatViewCart = (products, dataLayer) => {
    if (!enable) { return }
    if (products.length === 0) { return }
    clearECDataLayer(dataLayer)
    dataLayer.push({
        event: "view_cart",
        ecommerce: {
            currency: "PLN",
            value: products[0].totalAmount, //wartość produktów w koszyku po odliczeniu rabatów
            items: [
                {
                    item_id: products[0].id,
                    item_name: products[0].name,
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Paysafecard",
                    item_category: products[0].shortName,
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "psc_products",
                    item_list_name: "PSC products",
                    item_variant: products[0].id,
                    location_id: "Shop",
                    price: products[0].totalAmount,
                    quantity: 1
                }
            ]
        }
    });
}

const addECStatBeginCheckout = (dataLayer, products) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)
    dataLayer.push({
        event: "begin_checkout",
        ecommerce: {
            items: [
                {
                    item_id: products[0].id,
                    item_name: products[0].name,
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Paysafecard",
                    item_category: products[0].shortName,
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "psc_products",
                    item_list_name: "PSC products",
                    item_variant: products[0].id,
                    location_id: "Shop",
                    price: products[0].totalAmount,
                    quantity: 1
                }
            ]
        }
    });
}

const addECStatPaymentInfo = (dataLayer, products, paymentType) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)
    dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
            currency: "PLN",
            value: products[0].totalAmount,
            coupon: "",
            payment_type: paymentType,
            items: [
                {
                    item_id: products[0].id,
                    item_name: products[0].name,
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Paysafecard",
                    item_category: products[0].shortName,
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "psc_products",
                    item_list_name: "PSC products",
                    item_variant: products[0].id,
                    location_id: "Shop",
                    price: products[0].totalAmount,
                    quantity: 1
                }
            ]
        }
    });
}

const addECStatShippingInfo = (products, dataLayer) => {
    if (!enable) { return }
    clearECDataLayer(dataLayer)
    dataLayer.push({
        event: "add_shipping_info",
        ecommerce: {
            currency: "PLN",
            value: products[0].totalAmount,
            coupon: "",
            shipping_tier: "Email/SMS",
            items: [
                {
                    item_id: products[0].id,
                    item_name: products[0].name,
                    affiliation: "BillBird",
                    coupon: "",
                    currency: "PLN",
                    discount: 0.00,
                    index: 0,
                    item_brand: "Paysafecard",
                    item_category: products[0].shortName,
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: "psc_products",
                    item_list_name: "PSC products",
                    item_variant: products[0].id,
                    location_id: "Shop",
                    price: products[0].totalAmount,
                    quantity: 1
                }
            ]
        }
    });
}

export {
    addECStatBuy,
    addECStatCart,
    addECStatCartStep,
    addECStatProductRemovedFromCart,
    addECStatProductAddToCart,
    addECStatClickProduct,
    clearECDataLayer,
    addECStatViewCart,
    addECStatBeginCheckout,
    addECStatPaymentInfo,
    addECStatShippingInfo,
    initEC
}