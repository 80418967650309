import { useEffect, useState, useRef } from 'react'
import { getConfig } from './common/MPClient'
import { getRouting } from './app/Router'
import { initEC } from './common/ECommerceClient'

function App() {
	const [isLoading, setIsLoading] = useState(true)
	const [products, setProducts] = useState([])
	const [paymentMethods, setPaymentMethods] = useState([])
	const [errorMessage, setErrorMessage] = useState("")
	const [product, setProduct] = useState()
	const [blikStatusData, setBlikStatusData] = useState({ user: '', task: ''})
	const containerRef = useRef()
	const [dataLayer, setDataLayer] = useState()

	const api = new URLSearchParams(window.location.search).get('api');
	if (localStorage.getItem('api') == null && api != null) {
		localStorage.setItem('api', api)
	}

	useEffect(() => {
		localStorage.setItem('isClientDataFilled', false);
		
		setDataLayer(initEC())
		setIsLoading(true)
		getConfig().then((data) => {
			setProducts(data.productData)
			data.paymentData.sort(function (a, b) {
				return a.shortName === "BLIK" 
						   ? -1    // Move it down the list
						   : 0;   // Keep it the same
			});
			setPaymentMethods(data.paymentData)
			setIsLoading(false)
		}).catch(error => {
			console.log(error)
		})
	}, [])

	return (
		<>
			{ getRouting({
				product,
				products,
				isLoading,
				paymentMethods,
				errorMessage,
				containerRef,
				blikStatusData,
				dataLayer,
				setProducts,
				setPaymentMethods,
				setIsLoading,
				setErrorMessage,
				setProduct,
				setBlikStatusData
			})}
		</>
	)

}

export default App
