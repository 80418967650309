//TODO spinner or other shit to indicate is loading
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import './Loading.css'

const Loading = () => {
	return (
		<Loader
			type="Puff"
			color="#00BFFF"
			height={100}
			width={100}
			className="centered" />
	)
}

export default Loading